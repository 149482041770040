.landingpage-container {
  display: flex;
  align-items: space-between;
  height: 100vh;
}

.landing-left {
  min-width: 30%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: #ef5350;
}
.landing-img {
  max-width: 100%;
  height: 100%;
}

.regular {
  display: none;
}

.homepage-container {
  margin-left: 20px;
}

.cards-summary-container {
  display: flex;
  justify-content: space-between;
}

.team-cards {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 2rem;
  margin-top: 1rem;
  padding: 2rem;
}

.team-cards-summary-open {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  column-gap: 2rem;
  row-gap: 2rem;
  margin-top: 1rem;
  padding: 2rem;
}

.team-card {
  padding: 0.5rem;
  max-width: 30rem;
  min-width: 30rem;
  box-shadow: -10px -10px 1px 1px #d0e1d8;
  margin-bottom: 2rem;
  border-radius: 10px;
  background-color: #fbfbfb;
  z-index: 0;
  height: 13rem;
}

.team-card-items {
  margin-top: 1rem;
  display: flex;
  max-height: 60%;
  overflow-y: auto;
}

.team-card-pkmn-div {
  margin-top: 1rem;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  margin-bottom: 20px;
}

.team-card-pkmn {
  position: relative;
  z-index: 0;
  width: 5rem;
  height: 5rem;
}

.team-card-pkmn-background {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 4rem;
  height: 4rem;
}

.team-card-pkmn-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
  background-image: url('https://i.ibb.co/vD2ZxPy/pokeball-logo-DC23868-CA1-seeklogo-com.png');
  background-size: 4rem 4rem;
  opacity: 0.25;
}

.card-h2 {
  border-bottom: 4px solid #ef5350;
}

.team-summary-div {
  padding: 0.5rem;
  max-width: 35rem;
  min-width: 35rem;
  box-shadow: -10px -10px 1px 1px #d0e1d8;
  margin-bottom: 2rem;
  border-radius: 10px;
  background-color: #fbfbfb;
  z-index: 0;
  padding-left: 1rem;
  padding-right: 1rem;
  margin-top: 0;
  position: relative;
  margin-right: 2rem;
  min-height: 52rem;
}

.summary-display {
  margin-top: 0;
  white-space: pre-line;
  overflow-y: auto;
}

.summary-button {
  position: absolute;
  bottom: 0;
  right: 0;
  padding: 1rem;
}

.team-buttons {
  display: flex;
  justify-content: space-between;
}

.comment-display {
  margin-top: 0;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  align-items: center;
  height: 80%;
  padding-top: 2rem;
  overflow-x: auto;
}

.comment {
  background-color: white;
  box-shadow: -5px -5px 1px 1px #ef5350;
  border-bottom: 2px solid black;
  border-right: 2px solid black;
  width: 80%;
  border-radius: 4px;
  padding: 1rem;
  display: flex;
  flex-direction: column;
}

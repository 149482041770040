.create-team-header {
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.create-team-form-container {
  display: flex;
  gap: 3rem;
  justify-content: center;
}

.create-team-card-preview {
  margin-top: 2rem;
  border-radius: 10px;
  background-color: #fbfbfb;
  box-shadow: -10px -10px 1px 1px #d0e1d8;
  padding: 1rem;
  width: 40rem;
  height: fit-content;
}

.team-pokemon-divs-container {
  display: flex;
  gap: 2rem;
  flex-wrap: wrap;
  justify-content: center;
}

.team-pokemon-div p {
  margin-bottom: 0;
}

.team-pokemon {
  width: 10rem;
  height: 2.5rem;
  border-radius: 10px;
  background-color: #daede3;
  box-shadow: inset 0 0 3px rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
}

.summary-display-preview {
  width: 90%;
  min-height: 11.8rem;
  border-radius: 10px;
  background-color: #ffffff;
  box-shadow: inset 0 0 3px rgba(0, 0, 0, 0.5);
  padding: 2rem;
  white-space: pre-line;
  overflow-y: auto;
  max-height: 20rem;
}

.team-pokemon-summary {
  margin-top: 2rem;
  border-radius: 10px;
  background-color: #fbfbfb;
  box-shadow: -10px -10px 1px 1px #d0e1d8;
  padding: 1rem;
  width: 40rem;
  display: flex;
  flex-direction: column;
  overflow-y: auto;
}

.summary-input-div {
  margin-top: 2rem;
  border-radius: 10px;
  background-color: #fbfbfb;
  box-shadow: -10px -10px 1px 1px #d0e1d8;
  padding: 1rem;
  width: 40rem;
  display: flex;
  flex-direction: column;
}

.card-h2-team-create {
  border-bottom: 4px solid #ef5350;
}

.summary-form {
  display: flex;
  flex-direction: column;
}

.select-pokemon-form {
  display: flex;
  flex-wrap: wrap;
  gap: 3rem;
  justify-content: center;
}

.select-pokemon-div {
  display: flex;
  flex-direction: column;
  width: 10rem;
  align-items: center;
}

.select-pokemon-div select {
  width: 100%;
  height: 2.5rem;
}

.select-pokemon-div p {
  margin-bottom: 0;
}

.teamname-input-div {
  display: flex;
  gap: 1rem;
  align-items: center;
  justify-content: center;
}

.errors-div {
  display: flex;
  flex-direction: column;
  gap: 0;
}

.profile-dropdown {
  position: absolute;
}

.hidden {
  display: none;
}

.navbar-list {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  list-style: none;
  align-items: center;
  font-size: 1rem;
  min-width: 5.2rem;
  max-width: 5.2rem;
}

.navbar-link {
  text-decoration: none;
  color: black;
  font-weight: bold;
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.nav-button {
  background-color: none;
  background: none;
  border: none;
  font-size: 1rem;
  font-family: JosefinSans-Regular;
  font-weight: bold;
  cursor: pointer;
  height: 100%;
  width: 100%;
}

.nav-button-div {
  width: 5.2rem;
  height: 5rem;
  display: flex;
  justify-content: center;
  align-items: center;
}

.nav-button-div:hover {
  background-color: #cc4341;
}

.navbar-item {
  word-wrap: break-word;
  text-align: center;
  height: 5rem;
  width: 5.2rem;
}

.navbar-item:hover {
  background-color: #cc4341;
}

.signup-div {
  display: flex;
  flex-direction: column;
  padding: 1rem;
  background-color: #fbfbfb;
  box-shadow: -10px -10px 1px 1px #e45d5d;
  border-radius: 10px;
  border-right: 2px solid #000000;
  border-bottom: 2px solid #080000;
  width: 20rem;
  height: fit-content;
}

.signup-div h1 {
  margin-bottom: 0;
}

.signup-div form {
  display: flex;
  flex-direction: column;
  row-gap: 1rem;
  justify-content: center;
  align-items: center;
}

.signup-div p {
  margin-bottom: 0;
  margin-top: 0;
}

.signup-div input {
  border-radius: 5px;
  border: none;
  background-color: #e45d5d;
  box-shadow: inset 0 0 3px rgba(0, 0, 0, 0.5);
  width: 90%;
  height: 2rem;
}

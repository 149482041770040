@import url('./fonts.css');

.navbar {
  background-color: #ef5350;
  display: flex;
  flex-direction: column;
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
}

body {
  font-family: JosefinSans-Regular;
  margin: 0;
  background-color: #ebf5f0;
}

.content {
  margin-left: 82px;
}

.gen-button {
  border: 2px solid black;
  background-color: white;
  font-family: JosefinSans-Regular;
  margin: 5px;
}

.gen-button:hover {
  cursor: pointer;
}

.gen-btn {
  border: none;
  height: 100%;
  width: 100%;
  background: transparent;
  font-family: JosefinSans-Regular;
}

.gen-btn:hover {
  cursor: pointer;
}
